import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useSWR from 'swr'
import {camelCase} from 'lodash'
import { useSWRConfig } from 'swr'
import Spinner from "./common/Spinner";
import * as XLSX from 'xlsx';
import { EyeIcon, CloseIcon } from "./icons";
import { Input } from "./common/Input";
import * as utils from '../utils/utils'
import Button from "./common/Button";
import { upperCase } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { config } from '../config';

var moment = require('moment');

const formatCurrency = (value) => {
  if (value === undefined) { return 0; }
  return value.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export default function Search() {
  const API_URL_STUDENTS = '/api/students?enabled=SI';
  const API_URL_INSURANCES = '/api/insurances';

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [searchLabel, setSearchLabel] = useState('');
  const [dataInvoice, setDataInvoice] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [insuranceId, setInsuranceId] = useState(null);
  const shouldFetch = studentId !== '' || insuranceId !== '';

  const API_URL_SEARCH = `/api/billings/search/student/${studentId}/insurance/${insuranceId}`;
  
  let { data, error, isLoading, isValidating } = useSWR(shouldFetch ? API_URL_SEARCH : null, (url) => fetch(url).then(res => res.json()))
  const { data: dataStudents } = useSWR(API_URL_STUDENTS, (url) => fetch(url).then(res => res.json()))
  const { data: dataInsurances } = useSWR(API_URL_INSURANCES, (url) => fetch(url).then(res => res.json()))
  const isAnyLoading = (shouldFetch && !data);

  const documentRowStyle = "flex cursor-pointer text-slate-500 text-xs hover:font-bold hover:underline w-[200px] gap-1";

  useEffect(() => {
    if (search) {
      setDataInvoice(data)
    } else {
      setDataInvoice(null)
    }
  }, [data, search, studentId, insuranceId])

  const onView = (document, type) => {
    // let url = `http://localhost:3000/api/billings/document/${type}/document/${document._id}`
    switch (type) {
      case 'factura': window.open("factura/" + document._id); break;
      case 'nota-de-credito': window.open("nota-de-credito/" + document.parentId); break;
      case 'recibo': window.open("recibo/" + document._id); break;
      case 'nota-de-debito': window.open("nota-de-debito/" + document.parentId); break;
      default: console.log('error');
    }    
  }

  const getStudentCompleteName = (student) => {
    return `${student?.name} ${student?.lastName}`;
  }

  const getInvoicePeriodsWithoutDuplicates = (invoice) => {
    return [...new Set(invoice.periods?.map(period => period.name))]
      .map(name => name.toUpperCase())
      .join(', ');
  }

  const getReceipt = (dataInvoice, invoice, type) => {
    const receipts = dataInvoice?.receipts.filter(r => invoice.receipts.includes(r._id))
    const documentsList = [];

    if (type === 'recibo' || type === 'debito') {
      for (const receipt of receipts) {
        if (type === 'recibo') {
          documentsList.push(
            {
              _id: receipt._id,
              docType: 'Recibo',
              number: receipt.receiptNumber, 
              amount: receipt.amount,
            }
          )
        }
        if (type === 'debito') {
          if (receipt.debitNotes && receipt.debitNotes.length > 0) {
            for (const debitNote of receipt.debitNotes) {
              documentsList.push(
                {
                  _id: debitNote._id,
                  docType: 'Nota de Debito',
                  number: debitNote.debitNoteNumber, 
                  amount: debitNote.debitNoteAmount,
                  parentId: receipt._id,
                }
              )
            }
          }
        }
      }
    }

    if (type === 'credito') {
      for (const creditNote of invoice.creditNotes) {
        documentsList.push(
          {
            _id: creditNote._id,
            docType: 'Nota de Credito',
            number: creditNote.creditNoteNumber, 
            amount: creditNote.creditNoteAmount,
            parentId: invoice._id,
          }
        )
      }
    }
      
    return documentsList
  }

  const getListOptions = () => {

    return {
      students: dataStudents?.filter(s => s?.name?.toLowerCase().includes(search?.toLowerCase()) || s?.lastName?.toLowerCase().includes(search?.toLowerCase())),
      insurances: dataInsurances?.filter(i => i?.plan?.toLowerCase().includes(search?.toLowerCase())),
    }
  }

  return (
    <div className="h-full overflow-auto mt-4">
      <div className="w-full flex sticky top-0 z-10 bg-white pb-4 shadow px-4">
        <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight">Buscador</h1>
      </div>

      <div className="w-full flex bg-white rounded pb-4 px-4 pt-2">
        {
          <div className="flex flex-col w-full">
            <Input
              autoComplete="false"
              rightElement={
                <div className="cursor-pointer" onClick={() => { setSearch(''); setOpen(false); setDataInvoice(null); }}>
                  {search && <CloseIcon />}
                </div>
              }
              type="text"
              value={search}
              name="search"
              id="search"
              placeholder="Buscar por estudiante u obra social"
              onChange={(e) => {setSearch(e.target.value); setOpen(true);}}
            />
            <div className="relative w-full">
              <div className="absolute z-10 bg-white w-[1200px]" onBlur={() => setSearch('')}>
                {
                  open && search && (
                    <ul className="py-2 border border-gray-200 rounded max-h-[400px] overflow-scroll">
                      {
                        getListOptions().students.map(s => 
                          <li onClick={() => {setSearchLabel('Estudiante: ' + s.name + ' ' + s.lastName); setStudentId(s._id); setInsuranceId(null); setOpen(false); }} className="p-2 cursor-pointer hover:bg-gray-200 text-sm">Estudiante: {s.name + ' ' + s.lastName}</li>  
                        )
                      }
                      {
                        getListOptions().insurances.map(i => 
                          <li onClick={() => {setSearchLabel('Obra social: ' + i.plan); setInsuranceId(i._id); setStudentId(null); setOpen(false); }} className="p-2 cursor-pointer hover:bg-gray-200 text-sm">Obra social: {i.plan}</li>  
                        )
                      }

                      {
                        getListOptions().students.length === 0 && getListOptions().insurances.length === 0 && (
                          <li className="p-2 cursor-pointer hover:bg-gray-200 text-sm">Sin resultados</li>  
                        )
                      }
                    </ul>
                  )
                }
              </div>
            </div> 
          </div> 
        }
      </div>

      {
        (isAnyLoading) && (
          <div>
            <Spinner />
          </div>
        )
      }
      {
        dataInvoice && (
          <div className="flex flex-col overflow-x-auto mb-20 px-4">
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="inline-block w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-x-auto rounded-xl border">
                  <table className="text-left text-sm font-light rounded-xl table-auto w-full">
                    <thead className="border-b font-medium  bg-slate-50 rounded-xl">
                      <tr className="text-slate-400">
                        <th className="border-b  font-medium p-4 pl-8 pt-0 w-4 pb-3 text-slate-400 text-left">#</th>
                        <th scope="col" className="px-2 py-4 truncate">Periodos</th>
                        <th scope="col" className="px-2 py-4 truncate">Obra Social</th>
                        <th scope="col" className="px-2 py-4 truncate">Estudiantes</th>
                        <th scope="col" className="px-2 py-4 w-[200px]">Factura</th>
                        <th scope="col" className="px-2 py-4 w-[200px]">Recibos</th>
                        <th scope="col" className="px-2 py-4 w-[200px]">N de Credito</th>
                        <th scope="col" className="px-2 py-4 w-[200px]">N de Debito</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        dataInvoice?.invoices?.length ?
                          dataInvoice?.invoices?.map((invoice, index) => {
                              return (
                                <tr key={invoice._id} className={utils.cn('border-b last:border-b-0 hover:bg-gray-100', (index % 2 === 0) && 'bg-gray-50')}>
                                  <td className="text-left !text-xs border-b border-slate-100  p-4 pl-8 text-slate-500 ">{index + 1}</td>
                                  <td className="text-slate-500 !text-xs whitespace-nowrap px-2 py-4 max-w-[180px] overflow-hidden" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                      {getInvoicePeriodsWithoutDuplicates(invoice)}
                                    </div>
                                  </td>
                                  <td className="text-slate-500 !text-xs whitespace-nowrap px-2 py-4">
                                    {
                                      dataInsurances?.find(insurance => insurance._id === invoice.insurance).plan
                                    }
                                  </td>
                                  <td className="text-slate-500 !text-xs whitespace-nowrap px-2 py-4 max-w-[180px] overflow-hidden" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{invoice.students?.map(studentId => getStudentCompleteName(dataStudents?.find(student => student._id === studentId))).join(', ')}</td>
                                  <td className="whitespace-nowrap px-2 py-4 min-w-[200px]">
                                    <div className="flex flex-col gap-2">
                                      <div className={documentRowStyle} onClick={() => onView(invoice, 'factura')}><b>Nro</b> {invoice.invoiceNumber} ${invoice.invoiceAmount}</div>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-4 min-w-[200px]">
                                    <div className="flex flex-col gap-2">
                                      {
                                        getReceipt(dataInvoice, invoice, 'recibo').map(document => 
                                          <div className={documentRowStyle} onClick={() => onView(document, 'recibo')}><b>Nro</b> {document.number} ${document.amount}</div>
                                        )
                                      }
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-4 min-w-[200px]">
                                    <div className="flex flex-col gap-2">
                                      {
                                        getReceipt(dataInvoice, invoice, 'credito').map(document => 
                                          <div className={documentRowStyle} onClick={() => onView(document, 'nota-de-credito')}><b>Nro</b> {document.number} ${document.amount}</div>
                                        )
                                      }
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-4 min-w-[200px]">
                                    <div className="flex flex-col gap-2">
                                      {
                                        getReceipt(dataInvoice, invoice, 'debito').map(document => 
                                          <div className={documentRowStyle} onClick={() => onView(document, 'nota-de-debito')}><b>Nro</b> {document.number} ${document.amount}</div>
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                              );
                          })
                          : (
                            <tr className="border-b last:border-b-0 ">
                              <td colSpan={6} className="whitespace-nowrap px-6 py-4 font-medium">
                                Sin registros
                              </td>
                            </tr>
                          )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex gap-2 justify-center items-center p-4"></div>
          </div>
        )
      }
    </div>
  );
}