import { useParams } from "react-router-dom";
import React, { useRef } from "react";
import useSWR from 'swr'
import { validateNewContent } from "../utils/utils";
import Spinner from "./common/Spinner";
import { ReceiptPreview } from "./receipts/Receipt";

function Receipt() {
  const params = useParams();
  const receiptId = params.receiptId;
  const printRef = useRef(null);
  const API_URL = `/api/billings/documents/nota-de-debito/document/${receiptId}`;
  const { data, error } = useSWR(API_URL + '', (url) => fetch(url).then(res => res.json()))

  const totalWithholdings = data?.data?.withholdings?.reduce((total, withholding) => total + +withholding.amount, 0) || 0;
  const total = (+data?.data?.amount - totalWithholdings).toFixed(2);

  validateNewContent();

  let receipt = {
    ...data?.data,
    itemsDetail: data?.data?.itemsDetail,
    amountWithoutWithholdings: total,
  };
  
  if (error) console.log(error)

  return (
    <div className="h-full overflow-x-hidden overflow-y-auto">
      <div className="w-full flex flex-wrap sticky top-0 z-10 bg-white shadow px-4 pb-4">

      </div>
      {
        (!data) && (
          <div>
            <Spinner />
          </div>
        )
      }
      {
        error && (
          <div className="text-red-500">
            {/* ERROR... */}
          </div>
        )
      }

      {
        data && (
          <div ref={printRef}>
            <ReceiptPreview receiptPreviews={[receipt]} isGroupReceipt={true} isEditable={false} />
          </div>
        )
      }
    </div>
  );
}

export { Receipt };