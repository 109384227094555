import { useLocation, useParams, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import useSWR from 'swr'
import Spinner from "./common/Spinner";
import { DebitNoteDoc } from "./DebitNote/DebitNoteDoc";

// http://localhost:3000/nota-de-debito/670ff0b38c935fed71c191a3

function DebitNote() {
  const params = useParams();
  const receiptId = params.receiptId;
  const printRef = useRef(null);
  const API_URL = `/api/billings/documents/nota-de-debito/document/${receiptId}`;
  const { data, error } = useSWR(API_URL + '', (url) => fetch(url).then(res => res.json()))

  let debitNote = {
    ...data?.data,
  };

  if (error) console.log(error)

  return (
    <div className="h-full overflow-x-hidden overflow-y-auto">
      <div className="w-full flex flex-wrap sticky top-0 z-10 bg-white shadow px-4 pb-4">

      </div>
      {
        (!data) && (
          <div>
            <Spinner />
          </div>
        )
      }
      {
        error && (
          <div className="text-red-500">
            {/* ERROR... */}
          </div>
        )
      }

      {
        data && receiptId && (
          <div ref={printRef}>
            <DebitNoteDoc receipt={debitNote} invoiceId={data.data.invoice[0]._id} />
          </div>
        )
      }
    </div>
  );
}

export { DebitNote };
